import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import TranslationContainer from "../localization/TranslationContainer";
import { AldersgateContext } from '../context/aldersgate';
import UserLogin from "../components/user/UserLogin";
import NavBreadcrumbs from "../components/NavBreadcrumbs";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CenteredLoading from '../components/CenteredLoading';

import BallotIcon from '@mui/icons-material/Ballot';
import EditIcon from '@mui/icons-material/Edit';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ClassIcon from '@mui/icons-material/Class';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import EventHomeView from "../components/viewevent/EventHomeView";
import EventOverview from "../components/viewevent/EventOverview";
import ManageOverview from "../components/viewevent/ManageOverview";
import CheckInOverview from "../components/viewevent/CheckInOverview";
import ReportOverview from "../components/viewevent/ReportOverview";
import EditOverview from "../components/viewevent/EditOverview";
import CertificateOverview from "../components/viewevent/CertificateOverview";

    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function DisplayPage(props) {
        const { pageNumber, isLoading, eventData, updateInformation } = props;

        switch(pageNumber) {
            case 0:
                return <EventHomeView eventData={eventData} isLoading={isLoading} updateInformation={updateInformation} />;
            case 1:
                return <EventOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation} />;
            case 2:
                return <ManageOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation} />;
            case 3:
                return <EditOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation}/>;
            case 4:
                return <EditOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation}/>;
    
            default:
                return <EventOverview />;
        }
    }

    export default function ViewEvent(props) {
        const [value, setValue] = useState(0);
        const [eventData, setEventData] = useState({});
        const [isLoading, setIsLoading] = useState(true);
        const [snackbarOpen, setSnackbarOpen] = useState(false);
        const [snackbarMessage, setSnackbarMessage] = useState("");

        const [navPage, setNavPage] = React.useState(0);

        const authContext = React.useContext(AuthContext);
        const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
        const aldersgateContext = useContext(AldersgateContext);

        const translationContainer = new TranslationContainer();
        
        useEffect(() => {
          setAState(prev => {
              return({...authContext.accountState});
          });
        }, [authContext]);

        useEffect(() => {
            //get the tab from session storage
            let page = sessionStorage.getItem("evTab");
            if(page !== null && page !== undefined) {
                page = parseInt(page);
                if(!isNaN(page)) {
                    setNavPage(page);
                }
            }
        }, []);

        useEffect(() => {
            
            if(props.match.params && props.match.params.id) {

                aldersgateContext.GetFromAPI('event/' + props.match.params.id, null, 1).then((success, err) => {

                    if(success) {
                        parseEventData(success);
                        setIsLoading(false);
                    }

                    if(err) {
                        console.log("Error: " + err);
                    }
    
                }).catch((error) => {
                    console.log("Error: " + error);
                });
            }

            //unmount
            return () => {
                setEventData({});
            }
            
        }, [value]);

        //useEffect open snakbar
        useEffect(() => {

            if(snackbarMessage !== "") {
                setSnackbarOpen(true);
            }
            
        }, [snackbarMessage]);
        

        function parseEventData(props) {
            let event = {...props};
            event.participants = [];
            
            if(props.participants) {
                let index = 0;
                props.participants.forEach((participant) => {
                    event.participants.push({
                        id: index,
                        ...participant
                    });
                    index++;
                });
            }

            event.facilitators = [];
            if(props.facilitators) {
                let index = 0;
                props.facilitators.forEach((facilitator) => {
                    event.facilitators.push({
                        id: index,
                        ...facilitator
                    });
                    index++;
                });
            }

            setEventData(event);
            
        }

        function snackbarClose() {
            setSnackbarOpen(false);
        }

        const action = (
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={snackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          );

        function SnackBarElement(params) {
            return (
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={snackbarClose}
                    message={snackbarMessage}
                    action={action}
                />
            )
        }

        function DisplayEvent(isLoading, eventData, updateData) {
            let updateInformation = {updateData, setSnackbarMessage};

            return (
                <Box m={1.5} bgcolor="background.paper" >
                    <Grid container >
                        <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                            <NavBreadcrumbs crumbs={[{href:"/dashboard", text:translationContainer.GetTranslation('tables', 'dashboard', 'Dashboard')}]} currentPage={translationContainer.GetTranslation('tables', 'view-event', 'View Event')} />
                            
                            <DisplayPage pageNumber={navPage} isLoading={isLoading} eventData={eventData} updateInformation={updateInformation}/>
                            
                        </Grid>
                    </Grid>
                    <Box sx={{ height: 55 }} />
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            value={navPage}
                            onChange={(event, newValue) => {
                                sessionStorage.setItem("evTab", newValue);
                                setNavPage(newValue);
                            }}
                        >
                            <BottomNavigationAction aria-label="Event Feed" label={translationContainer.GetTranslation('tables', 'home', 'Home')} icon={<NewspaperIcon />} />
                            <BottomNavigationAction aria-label="Event details" label={translationContainer.GetTranslation('tables', 'details', 'Details')} icon={<BallotIcon />} />
                            <BottomNavigationAction aria-label="Manage event" label={translationContainer.GetTranslation('tables', 'manage', 'Manage')} icon={<ClassIcon />} />
                            <BottomNavigationAction aria-label="Edit event details" label={translationContainer.GetTranslation('tables', 'edit', 'Edit')} icon={<EditIcon />} />
                        </BottomNavigation>
                        <SnackBarElement />
                    </Paper>
                </Box>
            );
        }

        //check for token, block if not logged in
        if(aState.loggedIn === false) {
            return <UserLogin />
        }


        return (
        <div>
            {(isLoading) ? (
                    <CenteredLoading />
                  ) : (
                    DisplayEvent(isLoading, eventData, setValue)
                  )}
        </div>
      )
  }