import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DynamicEventList from './DynamicList/DynamicEventList';
import TranslationText from '../TranslationText';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import Title from './../Title';
import CenteredLoading from './../CenteredLoading';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';

    function GetMediumIcon(medium) {
      switch (medium) {
        case "Online":
          return <TapAndPlayIcon />;
          case "In-Person":
            return <GroupIcon />;
            case "Hybrid":
              return <StreetviewIcon />;
        default:
          break;
      }
    }

    function DisplayDate(fullDate) {
      var date = new Date(fullDate);
      var dd = date.getDate();

      var mm = date.getMonth()+1; 
      var yyyy = date.getFullYear();
      if(dd<10) 
      {
          dd='0'+dd;
      } 

      if(mm<10) 
      {
          mm='0'+mm;
      } 
      return(dd + "/" + mm + "/" + yyyy);
    }

    function LocalizedDateString(date) {
      var userLang = navigator.language || navigator.userLanguage;
      let dateToPrint = new Date(date);
      let dateString = dateToPrint.toLocaleDateString(userLang);

      return (dateString);
    }

    export default function EventsCard(props) {
        const {data} = props;

        const history = useHistory();

        const theme = useTheme();
        const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

        // function HandleClick(event, name) {          
        //   history.push("event/view/"+name);
        // }

        const ViewEvent = (event) => {
          sessionStorage.removeItem("evTab");
          let eventID = event;
          history.push("/event/view/" + eventID);
      }

        return (
          <Card sx={{minWidth:155}}>
              <CardContent>
                <Title>
                <TranslationText page='tables' dbKey='events-title'>Events</TranslationText>
                </Title>

                  {(!props.loaded) ? (
                    <CenteredLoading />
                  ) : (
                    <DynamicEventList eventListData={data} onRowClick={ViewEvent} />
                  )}

              </CardContent>
              <CardActions>
              <Button component={Link} to="event/new" size="medium" variant="contained" color="primary" startIcon={<AddIcon />}><TranslationText  page='tables' dbKey='create-event'>Create Event</TranslationText></Button>
              <Button component={Link} to="resources" size="medium" variant="contained" color="primary" startIcon={<CloudDownloadIcon />}><TranslationText  page='tables' dbKey='resources'>Resources</TranslationText></Button>
              </CardActions>
            </Card>
        );

    }
