
// This file is used to load the token from the session storage
const LoadAuthToken = () => {
    let impsesTokenString = sessionStorage.getItem('impses_token');
    let localTokenString = localStorage.getItem('ses_token');

    console.log("Loading local token from local storage: " + localTokenString);
    console.log("Loading impses token from session storage: " + impsesTokenString);

    if(localTokenString == undefined || localTokenString === null) {
        return {loggedIn:false, token:null, level:0, adID:null, expiry:null};
    }

    let tokenString = localTokenString;

    if(impsesTokenString !== undefined && impsesTokenString !== null) { //this would exist if we are logged in and impersonating a user, override our token with the session token
        console.log("Using impersonate session token");
        tokenString = impsesTokenString;
    }

    if(tokenString === undefined || tokenString === null) { //no login data, return empty object
        return {loggedIn:false, token:null, level:0, adID:null, expiry:null};
    }

    let userToken = JSON.parse(tokenString);
    userToken.loggedIn = true;
    return userToken;
}

const SaveAuthToken = (userToken) => {
    console.log("Saving local token: " + JSON.stringify(userToken));
    localStorage.setItem('ses_token', JSON.stringify(userToken)); //localStorage
}

const SaveSessionAuthToken = (userToken) => {
    sessionStorage.setItem('impses_token', JSON.stringify(userToken)); //localStorage
}

const ClearAuthToken = () => {
    console.log("Clearing local token");
    localStorage.removeItem('ses_token'); //localStorage
}

const ClearSessionAuthToken = () => {
    sessionStorage.removeItem('impses_token'); //localStorage
}

export { LoadAuthToken, SaveAuthToken, SaveSessionAuthToken, ClearSessionAuthToken, ClearAuthToken };