import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../../context/aldersgate';
import useToken from './../../../../auth/useToken';
import ADNewUserItem from './ADNewUserItem';
import ADPeopleList from '../../People/ADPeopleList/ADPeopleList';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import UserViewModal from '../../People/UserViewModal';
import Modal from '@mui/material/Modal';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function ADNewUsers(props) {
    const { token } = useToken();

    const [value, setValue] = useState(0);
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);
    
    const [filterData, setFilterData] = useState({authLevel: 0, trainingLevel: 0, lastLogin: firstDay});
    const [userListData, setUserListData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [actionModalOpen, setActionModalOpen] = useState(false);

    const aldersgateContext = useContext(AldersgateContext);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    useEffect(() => {
        SearchWithFilter();
    }, [value]);

    function ProcessUsers(users) {
        let userList = [];
        let count = 0;
        users.forEach(user => {
            userList.push({
                id: user.adID,
                elementID: count++,
                fName: user.fName,
                lName: user.lName,
                authLevel: user.authLevel,
                trainingLevel: user.trainingLevel,
                createdDate: user.createdDate,
                country: user.country,
                state: user.state,
                city: user.city,
                confirmButtonEnabled: true,
            });
        });

        userList.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdDate) - new Date(a.createdDate);
          });

        return userList;
    }

    function ProcessPeople(users) {
        let eventList = [];

        let index = 0;
        users.forEach(event => {
            let location = 'Unknown';
            if(event !== undefined && event !== null) {
                let loc = [];

                if(event.city !== undefined && event.city !== null && event.city !== '')   
                    loc.push(event.city);
                if(event.state !== undefined && event.state !== null && event.state !== '')
                    loc.push(event.state);
                if(event.country !== undefined && event.country !== null && event.country !== '')
                    loc.push(event?.country.toUpperCase());

                if(loc.length > 0) {
                    location = loc.join(', ');
                } else {
                    location = 'Unknown';
                }
            }

            let completedParticipants = event.participants ? event.participants.length : 0;


            eventList.push({
                ...event, id: index++, location: location, completedParticipants: completedParticipants
            });
        });

        return eventList;
    }

    function SearchWithFilter() {
        let query = '';
        // if(filterData.eventType !== 'All')
        //     query += '&eventType=' + filterData.eventType;
        // if(filterData.eventStatus !== 'All')
        //     query += '&eventStatus=' + filterData.eventStatus;
        if(filterData.startDate !== '')
            query += '&startDateRange=' + filterData.startDate;
        if(filterData.endDate !== '') {
            let endDate = new Date(filterData.endDate);
            endDate.setDate(endDate.getDate() + 1); // Add 1 day to include the last day
            query += '&endDateRange=' + endDate;
        }

        if(filterData.hasSFID) {
            query += '&hasSFID=true';
        } else {
            query += '&hasSFID=false';
        }

        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        query += '&includeOwner=true';


        query = "&authLevel=0&limit=20&offset=0";

        console.log(query);

        aldersgateContext.GetFromAPI('user/getlist/query/s', query, 1).then((success, err) => {
            if(success) {
                setUserListData(ProcessPeople(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/user/getlist/query/s?&api-key=foo' + query, {
    }

    // useEffect(() => {
    //     setEventData(props.eventData);
    // }, [props.eventData]);


    function viewUserDetails(event){
        let eventID = event;
        console.log('viewEventDetails: ' + eventID);

        //find the event in the reportData
        let eventItem = userListData.find((item) => item.id === eventID);

        console.log('eventItem: ' + JSON.stringify(eventItem, null, 4));

        setSelectedUser(eventItem);

        setActionModalOpen(true);

    }

    return (
        <Card sx={{minWidth:155}}>
            <CardContent>

                <ADPeopleList eventData={userListData}  onRowClick={viewUserDetails} />

                <Modal
                open={actionModalOpen}
                onClose={()=>{setActionModalOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <UserViewModal user={selectedUser} />
                    </Box>
                </Modal>
            </CardContent>
        </Card>
    );
}